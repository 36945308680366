:root {
  --black: #090909;
  --gold: #B38C5F;
  --white: #F3F3F3;
  --grey: #878787;
  --grey1: #2C2C2C;
  --grey2: #3C3C3C;
  --grey4: #878787;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #090909;
  padding: 0 !important;
}

#root{
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
}

@font-face {
  font-family: Avenir300;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Light.woff2") format("woff2"),
    url("../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Light.woff") format("woff");
}

@font-face {
  font-family: Avenir400;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.woff2") format("woff2"),
    url("../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.woff") format("woff");
}

@font-face {
  font-family: Avenir500;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Medium.woff2") format("woff2"),
    url("../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Medium.woff") format("woff");
}

@font-face {
  font-family: Avenir600;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Demi.woff2") format("woff2"),
    url("../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Demi.woff") format("woff");
}

@font-face {
  font-family: Avenir700;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Bold.woff2") format("woff2"),
    url("../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Bold.woff") format("woff");
}

@font-face {
  font-family: Avenir800;
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Heavy.woff2") format("woff2"),
    url("../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Heavy.woff") format("woff");
}

.link {
  cursor: pointer;
  font-size: 18px;
  position: relative;
  white-space: nowrap;
  color: var(--white);
  text-decoration: none;
}

.link::before,
.link::after {
  position: absolute;
  margin-top: 10px;
  width: 100%;
  height: 1px;
  background: currentColor;
  top: 100%;
  left: 0;
  pointer-events: none;
}

.link::before {
  content: '';
}

.link--metis::before {
  transform-origin: 100% 50%;
  transform: scale3d(0, 1, 1);
  transition: transform 0.3s;
}

.link--metis:hover::before {
  transform-origin: 0% 50%;
  transform: scale3d(1, 1, 1);
}
